body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDataGrid-colCellTitle {
  overflow: visible !important;
  text-overflow: unset !important;
  white-space: normal !important;
}
.MuiDataGrid-columnsContainer {
}
/* thead {
  display: block;
}
tbody {
  overflow-y: scroll;
  height: 500px;
}

td, th {
  width: 200px;
}
 */

 .table-area {
  position: relative;
  z-index: 0;
  margin-top: 10%;
}

table.responsive-table {
  display: table;
  /* required for table-layout to be used (not normally necessary; included for completeness) */
  table-layout: fixed;
  /* this keeps your columns with fixed with exactly the right width */
  width: 100%;
  /* table must have width set for fixed layout to work as expected */
  height: 100%;
}

/* table.responsive-table thead {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  line-height: 3em;
  table-layout: fixed;
  display: table;
}



table.responsive-table td {
  line-height: 2em;
}

table.responsive-table tr > td,
table.responsive-table th {
  text-align: left;
} */

/* tr {
  width: 100%;
  display: inline-table;
  table-layout: fixed;
  }

  
table{
   height:100vh;              
   display: block;
  }
tbody{
    overflow-y: scroll;      
    height: 90vh;          
    width: 100%;
    position: absolute;
  } */

 .tableFixHead { 
   overflow-y: auto; height: 1100px;
   margin-left: -4%;
  }
 .tableFixHead thead th {
   position: sticky; top: 0; z-index: 1; 
  }
 .tableFixHead tbody th {
   position: sticky; left: 0;
  }
  
  /* Just common table stuff. Really. */
 table  {
   border-collapse: collapse; width: 100%;
  }
 th, td {
   padding: 8px 16px;
  }
 th {
   background:#f4f4f4;
  }
  